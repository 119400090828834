import { useEffect } from 'react';

function PitfallMiner() {
    useEffect(() => {
        const domain = window.location.origin;
        window.location.href = `${domain}/pitfall_miner/index.html`;
    }, []);
    return null;
}

export default PitfallMiner;